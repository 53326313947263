@charset "utf-8";



#socialArea {
	height: auto!important;

	.sns-list {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 8px;

		li {
			font-size: 0;
			line-height: 1;
			vertical-align: bottom;
			margin-right: 18px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}